import  { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import Toastify for notifications
import { useAuth } from '../../AuthContext';
const OAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const user = query.get('user');
    const redirectTo = query.get('redirect_to');
    // alert(redirectTo)
    if (token && user) {
        localStorage.removeItem('access_token'); // Remove token
        localStorage.removeItem('user'); // Remove user details
        localStorage.setItem('access_token', token);
        localStorage.setItem('user',user);
        setIsAuthenticated(true);
        if(redirectTo){
           window.location.href = redirectTo+"?access_token="+token+"&user="+user;
        }
        navigate('/dashboard', { state: { successMessage: 'Login successful!' } });
     
    }
    else {
        setIsAuthenticated(false); 
        toast.error("An error occurred. Please try again.");
      }
  }, [setIsAuthenticated, location, navigate]);


};
export default OAuthCallback;
