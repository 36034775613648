import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api'; // Assuming api instance is set up
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import { useAuth } from '../../AuthContext';
const Login = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirect_to'); // Get 'redirect_to' from URL

  useEffect(() => {
    // If there's a logout message in the state, display the toast
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
     
    }
  }, [location.state]);
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({}); // For individual field errors
  const navigate = useNavigate();

  // Form validation (frontend)
  const validateForm = () => {
    if (!email || !password) {
      setErrors({
        email: !email ? 'Email is required' : '',
        password: !password ? 'Password is required' : '',
      });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    // Clear previous field errors
    setErrors({});

    if (!validateForm()) return;

    try {
      const response = await api.post('/login', { email, password });

      if (response.data.success) {
        localStorage.removeItem('access_token'); // Remove token
        localStorage.removeItem('user'); // Remove user details
        localStorage.setItem('access_token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        setIsAuthenticated(true);
        if (redirectTo) {
      
          window.location.href = redirectTo+"?access_token="+response.data.token+"&user="+JSON.stringify(response.data.user);
        } else {
  
          navigate('/dashboard', { state: { successMessage: 'Login successful!' } });
        }
      } else {
        setIsAuthenticated(false);
        toast.error(response.data.message || 'Invalid credentials');
      }
    } catch (err) {
      // If API response has field-specific errors
      if (err.response && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleGoogleLogin = () => {
   

  const googleLoginBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_URL;

  const googleLoginUrl = redirectTo
    ? `${googleLoginBaseUrl}?redirect_to=${encodeURIComponent(redirectTo)}`
    : googleLoginBaseUrl;

 
  window.location.href = googleLoginUrl;

   
  };

  return (
    <div className="login-container">
      <ToastContainer /> 
      <div className="login-box">
        <h2>TPT-Genie Login</h2>

      
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            placeholder="Type your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error-message">{errors.email}</p>} 
        </div>

      
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Type your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p className="error-message">{errors.password}</p>} 
        </div>

     
       

        <button className="login-button" onClick={handleLogin}>
          Login
        </button>

       
        <div className="separator">
          <span>-----------------------OR-----------------------</span>
        </div>

     
        <div className="alternative-options">
          <button className="google-login-button" onClick={handleGoogleLogin}>
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google Icon"
            />
            Continue with Google
          </button>
          <a href="/forgot-password" className="forgot-password-link">
            Forgot Password?
          </a>
        </div>

       
        <div className="signup-option">
          <p>Don't have an account?</p>
          <a href="/register" className="signup-link">Sign Up</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
