import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from './Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import CommonTitle from './CommonTitle';

const FavoriteProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedFilter, setSelectedFilter] = useState({
    price: '',
    rating: '',
    reviews: ''
  });

  const PAGE_SIZE = 10; // Define how many products to display per page

  // Function to fetch products
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await api.get('/user-favorite-products', {
        params: {
          page: currentPage,
          limit: PAGE_SIZE,
          ...selectedFilter,
          search,
        },
      });

      if (response.status === 200) {
        setProducts(response.data.data.data); // Adjust according to your API response structure
        setTotalPages(response.data.data.last_page); // Assuming the API returns the total number of pages
      }
    } catch (error) {
      setError('Error fetching products');
      console.error('Error fetching existing products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(); // Fetch products when component mounts or when currentPage, search, or selectedFilter changes
  }, [currentPage, selectedFilter]);

  // Handle search button click
  const handleSearch = () => {
    setCurrentPage(1); // Reset to the first page on search
    fetchProducts();
  };

  // Handle filter changes and reset other filters
  const handleFilterChange = (type, value) => {
    setSelectedFilter({
      price: type === 'price' ? value : '',
      rating: type === 'rating' ? value : '',
      reviews: type === 'reviews' ? value : ''
    });
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleResetFilters = () => {
    setSearch(''); // Reset search term
    setSelectedFilter({
      price: '',
      rating: '',
      reviews: ''
    }); // Reset filters
    setCurrentPage(1); // Reset to first page
    fetchProducts(); // Fetch products with reset filters
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        const response = await api.delete(`/products/${productId}`);
        if (response.status === 200) {
          setProducts(products.filter(product => product.id !== productId)); // Update local state
          alert('Product deleted successfully');
        }
      } catch (err) {
        alert('Error deleting product');
        console.error('Error deleting product:', err);
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">
        <ToastContainer />
        {/* title */}
        <CommonTitle title={'Favorite Products'} />
        <div className='container'>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
            <input
              type="text"
              className='form-control w-100'
              placeholder="Search products..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span> <button className='btn btn-primary' onClick={handleSearch}>Search</button></span>
            <span><button className='btn btn-primary' onClick={handleResetFilters}>Reset</button></span>
          </div>

          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', flexWrap: 'wrap', justifyContent: 'end' }}>
            {/* Price Filter */}
            <select value={selectedFilter.price} onChange={(e) => handleFilterChange('price', e.target.value)}>
              <option value="">Sort by Price</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>

            {/* Rating Filter */}
            <select value={selectedFilter.rating} onChange={(e) => handleFilterChange('rating', e.target.value)}>
              <option value="">Sort by Rating</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>

            {/* Reviews Filter */}
            <select value={selectedFilter.reviews} onChange={(e) => handleFilterChange('reviews', e.target.value)}>
              <option value="">Sort by Reviews</option>
              <option value="low_to_high">Low to High</option>
              <option value="high_to_low">High to Low</option>
            </select>
          </div>
          <div className='table-responsive'>
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Seller</th>
                  <th>Price</th>
                  <th>Total Reviews</th>
                  <th>Average Rating</th>
                  <th>Grade</th>
                  <th>Subject</th>
                  <th>Format</th>
                  <th>Tags</th>
                  <th>Product URL</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product) => (
                    <tr key={product.id}>
                      <td>
                        <img
                          src={product.image}
                          alt={product.name}
                          style={{ width: '100px', height: '100px' }}
                        />
                      </td>
                      <td>{product.name}</td>
                      <td>{product.seller}</td>
                      <td>{product.price || 'N/A'}</td>
                      <td>{product.total_reviews || 'N/A'}</td>
                      <td>{product.average_rating || 'N/A'}</td>
                      <td>{product.grade}</td>
                      <td>{product.subject}</td>
                      <td>{product.format}</td>
                      <td>{product?.tags.map((tag, tagIndex) => (
                        (product?.tags?.length > tagIndex + 1) ? tag + ', ' : tag
                      ))}</td>
                      <td>
                        <a href={product.product_url} target='_blank' rel='noopener noreferrer'>
                          {product.product_url}
                        </a>
                      </td>
                      <td>
                        <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12">No products found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
         { products.length > 0 && (
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <span>
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  disabled={index + 1 === currentPage}
                  style={{
                    margin: '10px 5px 0 5px',
                    padding: '5px 10px',
                    backgroundColor: index + 1 === currentPage ? '#00B3D6' : '#e0e0e0',
                    color: index + 1 === currentPage ? '#fff' : '#000',
                    border: 'none',
                    borderRadius: '3px',
                    cursor: index + 1 === currentPage ? 'default' : 'pointer'
                  }}
                >
                  {index + 1}
                </button>
              </span>
            ))}
          </div>
          )}
        </div>
         
      </div>
    </div>
  );
};

export default FavoriteProducts;
