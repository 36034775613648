import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'; // Assuming api instance is set up
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token] = useState(new URLSearchParams(window.location.search).get('token'));
  const [email] = useState(new URLSearchParams(window.location.search).get('email'));
  const [errors, setErrors] = useState({});

   // Form validation (frontend)
   const validateForm = () => {
    const newErrors = {};

    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleResetPassword = async () => {
    // Clear previous field errors
    setErrors({});

    if (!validateForm()) return;
    try {
      const response = await api.post('/reset-password', { password, token, email });
      navigate('/login', { state: { successMessage: response.data.message } });
    } catch (err) {
         // If API response has field-specific errors
      if (err.response && err.response.data.errors) {
        setErrors(err.response.data.errors);
      } else {
        if (err.response && err.response.data.message) {
            toast.error(err.response.data.message);
          }
        else{
            toast.error("Failed to reset password. Please try again.");
        } 
      }
    
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login-box">
      <h2>Reset Password</h2>
        {/* Password field */}
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Type your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p className="error-message">{errors.password}</p>} {/* Display password validation error */}
        </div>

        {/* Confirm Password field */}
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>} {/* Display confirm password error */}
        </div>
      <button className="login-button" onClick={handleResetPassword}>Reset Password</button>
      {/* OR separator */}
      <div className="separator">
        <span>-----------------------OR-----------------------</span>
        </div>

        {/* Already have an account */}
        <div className="signup-option">
          <a href="/login" className="signup-link">Back to Login</a>
        </div>
    </div>
    </div>
  );
};

export default ResetPassword;
