import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../App.css';
import SvgIcon from './SvgIcons';

const Sidebar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const location = useLocation(); // Get the current location
  const handleLogout = () => {
    localStorage.removeItem('access_token'); // Remove token
    localStorage.removeItem('user'); // Remove user details
    navigate('/login', { state: { successMessage: 'Logged out successfully!' } });
  };

  const handleNavigateToHome = () => {
    navigate('/dashboard');
  };

  const handleNavigateToAccount = () => {
    navigate('/account?tab=salesChannel');
  };
  const handleNavigateToPLans = () => {
    navigate('/plans');
  };
  const handleNavigateToSavedProducts = () => {
    navigate('/saved-products');
  }
  const handleNavigateToFavoriteProducts = () => {
    navigate('/favorite-products');
  }
  // Function to check if the current path is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="sidebar">
      <div className="sidebar-menu">
        <ul className='sidebar-menu-inner'>
          <div>
            <div className="sidebar-header">
              <SvgIcon.Logo />
            </div>

            <div className='accordian-btn' onClick={toggleMenu}>
              <span>Menu</span>
              <SvgIcon.ChevronDown />
            </div>
            {!menuOpen && <div className='submenu'>
              <li className={isActive('/dashboard') ? 'active' : ''} onClick={handleNavigateToHome}>
                <SvgIcon.Home />
                <span>Home</span>
              </li>
              <li className={isActive('/account') ? 'active' : ''} onClick={handleNavigateToAccount}>
                <SvgIcon.Account />
                <span>Account</span>
              </li>
              <li className={isActive('/saved-products') ? 'active' : ''} onClick={handleNavigateToSavedProducts}>
                <SvgIcon.ProductAnalystic />
                <span>Product Analytics</span>

              </li>
             
              <li className={isActive('/favorite-products') ? 'active' : ''} onClick={handleNavigateToFavoriteProducts}>
              <SvgIcon.SavedProduct />
                <span>Favorite Products</span>

              </li>
              
              <li className={isActive('/plans') ? 'active' : ''} onClick={handleNavigateToPLans}>
                <SvgIcon.Plans />
                <span>Plans</span>
              </li>
            </div>}

            
          </div>
          <li className='mt-auto logout' onClick={handleLogout}>
            <SvgIcon.Logout/>
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
