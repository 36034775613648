
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api'; // Assuming api instance is set up
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [errors, setError] = useState({}); // For individual field errors
  const handleForgotPassword = async () => {
    setError('');

    try {
      const response = await api.post('/forgot-password', { email });
    
      navigate('/login', { state: { successMessage: response.data.message } });
    } catch (err) {
      // If API response has field-specific errors
      if (err.response && err.response.data.errors) {
        setError(err.response.data.errors);
      } else {
        if (err.response && err.response.data.message) {
            toast.error(err.response.data.message);
          }
        else{
            toast.error("An error occurred. Please try again.");
        } 
      
      }
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login-box">
      <h2>Forgot Password</h2>
      <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
        type="email"
          id="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
       {errors.email && <p className="error-message">{errors.email}</p>}
      </div>
      <button className="login-button" onClick={handleForgotPassword}>Send Reset Link</button>
        {/* OR separator */}
        <div className="separator">
        <span>-----------------------OR-----------------------</span>
        </div>

        {/* Already have an account */}
        <div className="signup-option">
          <a href="/login" className="signup-link">Back to Login</a>
        </div>
    </div>
    </div>
  );
};

export default ForgotPassword;
