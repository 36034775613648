import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import Sidebar from './Sidebar'; // Import Sidebar component
import { ToastContainer, toast } from 'react-toastify'; // Toastify for notifications
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css'; // Import custom CSS
import api from '../../services/api';
import CommonTitle from './CommonTitle';

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'profile'; // Default to 'profile' if no tab is provided

  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    // Display toast message if redirected with a successMessage in state (like after logout)
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
    }
  }, [location.state]);

  useEffect(() => {
    // Set active tab from URL query param if present
    setActiveTab(initialTab);
  }, [initialTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Update the URL with the new tab parameter
    navigate(`?tab=${tab}`, { replace: true }); // Update URL without adding to history
  };

  return (
    <div className="main">
      <Sidebar /> {/* Display the Sidebar */}
      <div className="main-content">
        <ToastContainer /> {/* Toastify Container for API messages */}

        <CommonTitle title={'Account'} />
        {/* Tabs for switching between Profile and Sales Channel */}
        <div className='tab-container'>
          <div className="tabs">
            <button
              className={activeTab === 'salesChannel' ? 'active' : ''}
              onClick={() => handleTabChange('salesChannel')}
            >
              Sales Channel
            </button>
            <button
              className={activeTab === 'profile' ? 'active' : ''}
              onClick={() => handleTabChange('profile')}
            >
              Profile
            </button>
          </div>

          {/* Tab Content */}
          <div className="tab-content common-card">
            {activeTab === 'profile' && <ProfileTab />}
            {activeTab === 'salesChannel' && <SalesChannelTab />}
          </div>
        </div>
      </div>
    </div>
  );
};

// Profile Tab Component with Non-editable Form Fields
const ProfileTab = () => {
  const user = JSON.parse(localStorage.getItem('user')) || {
    name: 'SuperAdmin',
    email: 'superadmin@example.com',
  };

  return (
    <div className="profile-tab">
      <form className="profile-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={user.name}
            disabled
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={user.email}
            disabled
            className="form-control"
          />
        </div>
      </form>
    </div>
  );
};

// Sales Channel Tab Component with API Fetching
const SalesChannelTab = () => {
  const [salesChannelData, setSalesChannelData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the sales channel data
    const fetchSalesChannelData = async () => {
      try {
        const response = await api.get('sales-channel');

        if (response.data) {
          setSalesChannelData(response?.data?.data); // Set data if found
        } else {
          setSalesChannelData(null); // Set null if no data found
        }
      } catch (error) {
        console.error("Error fetching sales channel data:", error);
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    };

    fetchSalesChannelData();
  }, []);

  return (
    <div className="sales-channel-tab">
      {loading ? (
        <p>Loading...</p>
      ) : salesChannelData ? (
        <div className="sales-channel-info">
          <div className="form-group">
            <label htmlFor="user_name">Username</label>
            <input
              type="text"
              id="user_name"
              value={salesChannelData.user_name || ''}
              disabled
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={salesChannelData.email || ''}
              disabled
              className="form-control"
            />
          </div>
        </div>
      ) : (
        <p>No channel found.</p>
      )}

    </div>
  );
};

export default Account;
