import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const user = JSON.parse(localStorage.getItem('user'));
  const [metrics, setMetrics] = useState({
    grossRevenue: '---',
    avgOrderValue: '---',
    totalOrders: '---',
    lifetimeValue: '---',
    repeatRate: '---',
  });

  const [greeting, setGreeting] = useState('');
  const [menuOpen, setMenuOpen] = useState(false); // State for menu visibility
  const menuRef = useRef(null); // Ref for the menu

  useEffect(() => {
    const getGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        return 'Good Morning';
      } else if (currentHour < 18) {
        return 'Good Afternoon';
      } else {
        return 'Good Evening';
      }
    };

    setGreeting(getGreeting());

    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
    }

    const fetchMetrics = async () => {
      setTimeout(() => {
        setMetrics({
          grossRevenue: '$25,000',
          avgOrderValue: '$125',
          totalOrders: 200,
          lifetimeValue: '$5,000',
          repeatRate: '30%',
        });
      }, 1000);
    };

    fetchMetrics();
  }, [location.state]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleOptionClick = (option) => {
    closeMenu();
    if (option === 'Account') {
      navigate('/account?tab=profile'); // Navigate to the account profile tab
    } else if(option === 'Logout') {
      localStorage.removeItem('access_token'); // Remove token
      localStorage.removeItem('user'); // Remove user details
      navigate('/login', { state: { successMessage: 'Logged out successfully!' } });
    }
    else{
      console.log(option);
    }
  };

  // Close the menu when clicking outside of it
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      
    };
  }, []);

  return (
    <div className="main">
      <Sidebar />
      <div className="main-content">
        <ToastContainer />
        <div className="header">
          <h1 className='m-0'>{greeting}, {user?.name}</h1>
          <div className="user-info" onClick={toggleMenu} ref={menuRef}>
            <div className="user-circle">{user?.name.charAt(0).toUpperCase()}</div>
            <span className="user-name">{user?.name}</span>
            <span className={`arrow-icon ${menuOpen ? 'up' : 'down'}`}>
              <i className="fas fa-chevron-down"></i>
            </span>
            {menuOpen && (
              <div className="options-menu">
                <div className="user-details">
                  <p className="user-name">{user?.name}</p>
                  <p className="user-email">{user?.email}</p>
                </div>
                <div className="option" onClick={() => handleOptionClick('Account')}>
                  Account
                </div>
                <div className="option" onClick={() => handleOptionClick('Logout')}>
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dashboard-sections">
          <div className="dashboard-card">
            <i className="fas fa-dollar-sign dashboard-icon"></i>
            <h2>Gross Revenue</h2>
            <p>{metrics.grossRevenue}</p>
          </div>
          <div className="dashboard-card">
            <i className="fas fa-chart-line dashboard-icon"></i>
            <h2>Avg. Order Value</h2>
            <p>{metrics.avgOrderValue}</p>
          </div>
          <div className="dashboard-card">
            <i className="fas fa-shopping-cart dashboard-icon"></i>
            <h2>Total Orders</h2>
            <p>{metrics.totalOrders}</p>
          </div>
          <div className="dashboard-card">
            <i className="fas fa-users dashboard-icon"></i>
            <h2>Lifetime Value</h2>
            <p>{metrics.lifetimeValue}</p>
          </div>
          <div className="dashboard-card">
            <i className="fas fa-sync-alt dashboard-icon"></i>
            <h2>Repeat Rate</h2>
            <p>{metrics.repeatRate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
